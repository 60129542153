import { clearToken, sDecodedToken, sToken } from '@app/signals/token';
import { useSignals } from '@preact/signals-react/runtime';

export function useAuth() {
  useSignals();
  return {
    logout: clearToken,
    user: sDecodedToken.value?.email,
    groups: sDecodedToken.value?.groups,
  };
}

export function HasGroup(props: { group: string; children: React.ReactNode }) {
  const { group, children } = props;
  const userGroups = sDecodedToken.value?.groups;
  if (!userGroups) return null;
  if (userGroups.includes(group)) return <>{children}</>;
  return null;
}
