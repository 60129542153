import Link from 'next/link';

import AuthButton from './AuthButton';
import { Button } from '@app/components';
import { HasGroup, useAuth } from '@app/hooks/useAuth';

// import FreeNFT from '../../../../public/main-logo.svg';

export function Header() {
  const { user, groups } = useAuth();

  return (
    <div className='flex justify-between w-full gap-6'>
      <div className='flex flex-row items-center gap-6'>
        <Link href='/'>{/* <img src={FreeNFT.src} /> */}</Link>
        <HasGroup group='Write'>
          <Link href='/intent/create'>
            <Button size='small' variant='primary'>
              Create Intent
            </Button>
          </Link>
          <Link href='/channel/create'>
            <Button size='small' variant='primary'>
              Create Channel
            </Button>
          </Link>
        </HasGroup>
      </div>

      <div className='flex items-center gap-4'>
        <span className='relative group'>
          {user?.email}
          <div className='absolute right-0 hidden mt-1 text-xs border-t border-black group-hover:block whitespace-nowrap'>
            {Object.keys(groups ?? {}).map((group) => (
              <div key={group}>{group}</div>
            ))}
          </div>
        </span>
        <AuthButton />
      </div>
    </div>
  );
}
