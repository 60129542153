import { Button } from '@app/components';
import { useAuth } from '@app/hooks/useAuth';

const AuthButton = () => {
  const { logout, user } = useAuth();

  return (
    <div className='flex flex-row items-center gap-6'>
      {user && (
        <>
          {user}
          <Button onClick={logout} size='small' variant='secondary'>
            Sign out
          </Button>
        </>
      )}
    </div>
  );
};

export default AuthButton;
